export default {
  confirmation: {
    actions: {
      previewSurvey: 'Preview Survey',
      editSurvey: 'Edit Survey',
      publishSurvey: 'Publish Survey',
      updateSurvey: 'Update Survey',
      viewSurvey: 'View Survey',
      editTarget: 'Edit Target Audience',
      view: 'View',
      download: "Download",
      delete: 'Delete',
      upload: "Upload {fileTypes}",
    },
    alert: 'Please check and confirm your details. When you click on ‘Publish Survey’ it will go live and won’t be able to make changes',
    information: {
      surveyCompletes: 'Total survey completes',
      estimatedPrice: 'Estimated total price'
    },
    audience: {
      target: 'Target Audience',
      quantity: '{quantity} Audiences',
      notConfigured: 'One or more audiences are not configured yet, please go to Target Audience and finish configurations.',
      feasibilityOverride: 'These audiences currently have a feasibility override in place.'
    },
    languages: {
      title: 'Languages',
      description: 'If your survey text contains HTML or line breaks then you should use the XLIFF file versions for translation',
      surveyLanguage: 'Survey language',
      optionalTranslations: 'Optional translations',
      selectLanguage: 'Select Language',
      empty: {
        title: 'Empty translations',
        description: 'There are no {locale} translations.'
      },
      error: {
        unsupportedFile: 'Unsupported file format',
        unsupportedTargetLocale: 'Unsupported target locale',
        unknown: 'Unknown error while uploading translations',
        unsupportedLanguage: 'The {language} language is not supported.',
        cannotDeleteDefaultLocale: '{language} is the default locale for this survey and cannot be deleted',
        unknownTranslations: {
          title: 'Unknown translation strings',
          description: 'The following translations could not be found in the current survey and will not be added if you proceed.',
        },
      },
      headers: {
        original: 'Original',
        translation: 'Translation',
        context: 'Context',
        id: 'ID',
      },
      confirmUploadDialog: {
        title: 'Upload translations',
        headers: {
          filename: 'Filename',
          type: 'Type',
          sourceLocale: 'Source locale',
          targetLocale: 'Target locale',
          translations: 'Translations',
        },
        emptyFile: 'This file does not contain any translated strings',
        wrongSurvey: 'This file does not contain translations for this survey',
        actions: {
          confirm: 'Confirm',
          cancel: 'Cancel',
        }
      },
      viewTranslationsDialog: {
        title: '{locale} translations',
        loading: {
          title: 'Loading translations'
        }
      },
      confirmDeleteDialog: {
        title: 'Delete {locale} translations?',
        description: 'Are you sure you want to delete the {locale} translations',
        confirm: 'Confirm',
      }
    },
    errors: {
      audienceSummary: 'We\'re sorry, we couldn\'t load your audiences.'
    },
    translations: {
      backToConfirmation: 'Back to Launch Summary',
      title: 'Translation details',
      description: '',
      totalNoOfLanguageTranslations: 'Total no. of language translations',
      totalWordCount: 'Total word count',
      totalTranslationCost: 'Total translation cost',
      card: {
        title: 'Language: {lang}',
        visibleToRespondent: 'Visible to respondent',
        notVisibleToRespondent: 'Not visible to respondent',
        action: {
          view: 'View',
          downloadTranslationFile: 'Download translation file',
          delete: 'Delete',
        },
        status: {
          complete: 'Complete',
          pending: 'Pending',
        }
      }
    }
  }
}
