export default {
  surveyRail: {
    header: {
      questionList: 'Questions',
      import: 'Import',
      settings: 'Settings'
    },
    actions: {
      closeRail: 'Close Question List',
      openRail: 'Open Question List',
      expandRail: 'Expand Question List',
      collapseRail: 'Collapse Question List',
      expandPage: 'Expand Page',
      collapsePage: 'Collapse Page',
      expandBlock: 'Expand Block',
      collapseBlock: 'Collapse Block',
      deleteElement: 'Delete {element}',
      duplicateElement: 'Duplicate {element}',
      addItems: {
        menuTitle: 'Add to survey',
        node: 'Add Question',
        aiNode: 'Use AI to Create Question',
        page: 'Add Page',
        ruleSet: 'Add Logic',
        block: 'Add Block',
        useAItoCreateIntro: 'Use AI to Create Intro',
        addRandomization: 'Add Block Randomization',
        editRandomization: 'Edit Block Randomization'
      },
    },
    randomization: {
      blockTitle: 'Block Randomizer',
    },
    import: {
      noSurveys: 'There are no surveys available.',
      deleteImportedElement: 'Undo Import',
      back: 'Back to list',
      currentSurvey: 'Current Survey',
      noLogicAlert: 'Currently, logic, piping, and carry-forward import features are unavailable. To proceed, simply drag and drop your selected items into the survey. Kindly review the arrangement before finalizing.',
      confirm: {
        title: 'Your changes have not been saved.',
        message: 'How would you like to proceed?',
        leaveSave: 'Save changes and leave',
        leaveDiscard: 'Discard changes and leave',
        stay: 'Continue importing'
      }
    }
  }
}
