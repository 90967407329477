import Element, {BaseElementParams, ElementType} from "@/models/Element";
import {Referable} from "@/typings/global-types";
import Survey from "@/models/Survey";
import Page from "@/models/Page";
import RuleSet from "@/models/RuleSet";
import BlockElement from "@/models/BlockElement";
import Node from "@/models/Node";
import SurveyError, {SurveyErrorProperty, SurveyErrorType} from "@/models/errors/SurveyError";
import {Randomization} from "@/models/Randomization";

export default class Block extends Element implements Referable {

  static readonly ELEMENT_ICON = 'mdi-folder-network-outline';
  pageRandomization: Randomization;

  constructor(
    baseElementParams: BaseElementParams,
    public elements: Element[] = [],
    randomization: Randomization = new Randomization(),
  ) {

    super(baseElementParams);
    this._collapsed = true;
    this.type = ElementType.Block;

    this.elements = elements.length ? baseElementParams.survey.addElements(elements, this) : [];
    this.pageRandomization = randomization ? new Randomization(randomization.items, randomization.methods, randomization.limits) : new Randomization();
  }

  getCodeLabel(initialKey: string = 'block.initial'): string {
    return super.getCodeLabel(initialKey);
  }

  validate(survey: Survey) {
    this.validateElementsLength(survey);
  }

  validateElementsLength(survey: Survey) {
    survey.resetSurveyErrors(this.ref, null, [SurveyErrorProperty.BlockElements], [SurveyErrorType.Empty]);
    if (!this.elements.length) {
      survey.addSurveyError(new SurveyError(SurveyErrorType.Empty, SurveyErrorProperty.BlockElements, this.ref));
    }
  }

  addBlockElement(survey: Survey, element: BlockElement, index: number = null, validatePositionChange: boolean = true): BlockElement {
    return survey.addElement(element, index, validatePositionChange, this) as BlockElement;
  }

  addPage(survey: Survey, page: Page, index: number = null, validatePositionChange: boolean = true): Page {
    return this.addBlockElement(survey, page, index, validatePositionChange) as Page;
  }

  addRuleSet(survey: Survey, ruleSet: RuleSet, index: number = null, validatePositionChange: boolean = true): RuleSet {
    return this.addBlockElement(survey, ruleSet, index, validatePositionChange) as RuleSet;
  }

  getAllPages(): Page[] {
    return this.elements.filter(element => element instanceof Page) as Page[];
  }

  getAllNodes(): Node[] {
    return this.getAllPages().flatMap(page => page.nodes);
  }

  getAllRuleSets(): RuleSet[] {
    return this.elements.filter(element => element instanceof RuleSet) as RuleSet[];
  }

  getBlockElementIndex(element: BlockElement): number {
    return this.getBlockElementIndexByRef(element.ref);
  }

  getBlockElementIndexByRef(ref: string): number {
    return this.elements.findIndex(el => el.ref === ref);
  }
}
