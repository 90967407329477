import { Ref } from "@/typings/global-types";
import Survey from "@/models/Survey";

export class DuplicationMaps {
  // maps contain original ref -> duplicate ref
  duplicatedNodeRefsMap: Map<Ref, Ref> = new Map<Ref, Ref>();
  duplicatedPageRefsMap: Map<Ref, Ref> = new Map<Ref, Ref>();
  duplicatedOptionRefsMap: Map<Ref, Ref> = new Map<Ref, Ref>();

  private static duplicationMaps = new WeakMap<Survey, DuplicationMaps>();

  static set(survey: Survey) {
    // will overwrite any existing map, if one exists
    this.duplicationMaps.set(survey, new DuplicationMaps());
  }

  static get(survey: Survey): DuplicationMaps | undefined {
    return this.duplicationMaps.get(survey);
  }

  static clear(survey: Survey) {
    this.duplicationMaps.delete(survey);
  }
}
