import Page from "@/models/Page";
import Node from "@/models/Node";
import Block from "@/models/Block";
import Element from "@/models/Element";
import Survey from "@/models/Survey";
import i18n from "@/plugins/i18n";

export interface RandomizationItems {
  [ref: string]: {
    configuration: number[]
  }
}
export interface DisplayLimitItem {
  number: number;
  method: DisplayLimitMethod;
}

export interface LockedItems {
  [ref: string]: boolean;
}

export enum RandomizationMethod {
  Shuffle = 'shuffle',
  Reverse = 'reverse',
  None = 'none'
}

export enum RandomizationTypeProperty {
  NodeRandomization = 'nodeRandomization',
  PageRandomization = 'pageRandomization',
  BlockRandomization = 'blockRandomization',
}

export enum DisplayLimitMethod {
  Default = "default",
  LeastResponseCount = "least_responsecount"
}

export type RandomizationElementType = Page | Block | Survey;
export type RandomizationChildElementType = Node | Page | Block;

export class Randomization {
  static readonly DEFAULT_METHOD = RandomizationMethod.Shuffle;
  static readonly DEFAULT_DISABLED_LIMIT = 999999999; // an impossibly high limit to indicate display limit is disabled
  constructor(public items: RandomizationItems = {},
              public methods: RandomizationMethod[] = [RandomizationMethod.None],
              public limits: DisplayLimitItem[] = [
                { number: Randomization.DEFAULT_DISABLED_LIMIT, method: DisplayLimitMethod.LeastResponseCount }
              ]
  ) {}

  getRandomizedItems(includeLocked = true): [string, { configuration: number[]; }][] {
    // TODO: hardcoding to first group of items, update when support nested groups
    const items = Object.entries(this.items);
    return includeLocked ? items : items.filter(([_key, value]) => value.configuration[0] !== null);
  }

  getRandomizedItemsAsElements(element: RandomizationElementType): RandomizationChildElementType[] {
      const randomizedItems = this.getRandomizedItems();
      const randomizableItems = Randomization.getAllChildElements(element);
      return randomizableItems.filter(item => randomizedItems.some(([ref]) => item.ref === ref));
  }

  getRandomizedDisplayLimit(): DisplayLimitItem {
    // TODO: hardcoding to first group limit, update when support nested groups
    return this.limits?.[0];
  }

  isDisplayLimitDisabled() {
    // using an impossibly high limit to indicate whether an item is disabled to avoid a transformation in glue app
    // as in questions app, a limit that is equal to or higher than the number of items is considered disabled
    return (this.getRandomizedDisplayLimit()?.number ?? Randomization.DEFAULT_DISABLED_LIMIT) === Randomization.DEFAULT_DISABLED_LIMIT;
  }

  //TODO: these methods are all looking at first group, will need an update to look at specific group once groups supported
  isMethodReverse(): boolean {
    return this.getRandomizationMethod().includes(RandomizationMethod.Reverse);
  }

  isMethodShuffle(): boolean {
    return this.getRandomizationMethod().includes(RandomizationMethod.Shuffle);
  }

  getRandomizationMethod(): RandomizationMethod {
    // TODO: hardcoding to first group method, update when support nested groups
    return this.methods[0];
  }

  isRandomized(): boolean {
    return this.isMethodShuffle() || this.isMethodReverse();
  }

  hasRandomizedItems(): boolean {
    return this.getRandomizedItems().length > 0;
  }

  updateRandomization(element: RandomizationElementType, includeAllChildren: boolean = true, lockedItems: LockedItems = null, elements: RandomizationChildElementType[] = null) {
    // update items when child elements are added or removed to ensure they are randomized
    // note: absence of an item means it'll be pinned in place
    if (this.isRandomized()) {
      lockedItems ??= Randomization.getLockedItems(element);
      elements ??= Randomization.getAllChildElements(element);
      includeAllChildren ??= !(element instanceof Block);

      if (!includeAllChildren) {
        // update added/removed elements in between current subset only (just for blocks currently)
        const randomizedElements = this.getRandomizedItemsAsElements(element);
        const filteredItems = elements.filter(item => randomizedElements.some(el => el.ref === item.ref)) as RandomizationChildElementType[];
        const firstIndex = elements.indexOf(filteredItems[0]);
        const lastIndex = elements.indexOf(filteredItems[filteredItems.length - 1]);
        elements = elements.slice(firstIndex, lastIndex + 1);
      }

      if (elements.length <= 1) {
        Randomization.clearRandomization(element);
      } else {
        const userDefinedLimit = this.getRandomizedDisplayLimit().number;
        const newDisplayLimit = !this.isDisplayLimitDisabled() && userDefinedLimit >= elements.length ? elements.length : userDefinedLimit

        Randomization.setRandomization(
          element,
          lockedItems,
          elements,
          element[Randomization.getRandomizationProperty(element)].getRandomizationMethod(),
          newDisplayLimit
        );
      }
    }
  }

  static getRandomizationProperty(element: RandomizationElementType): RandomizationTypeProperty | null {
    if (element instanceof Page) {
      return RandomizationTypeProperty.NodeRandomization;
    }
    if (element instanceof Block) {
      return RandomizationTypeProperty.PageRandomization;
    }
    if (element instanceof Survey) {
      return RandomizationTypeProperty.BlockRandomization;
    }
    return null;
  }

  static getAllChildElements(element: RandomizationElementType): RandomizationChildElementType[] {
    if (element instanceof Page) {
      return element.nodes;
    }
    if (element instanceof Block) {
      return element.getAllPages();
    }
    if (element instanceof Survey) {
      return element.getAllBlocks();
    }
    return [];
  }

  static clearRandomization(element: RandomizationElementType) {
    element[this.getRandomizationProperty(element)] = new Randomization();
  }

  static isRandomizationSupported(element: Element | Survey): boolean {
    if (element instanceof Page) {
      return element.nodes.length > 1;
    }
    if (element instanceof Block) {
      return element.getAllPages().length > 1;
    }
    if (element instanceof Survey) {
      return element.getAllBlocks().length > 1;
    }
    return false;
  }

  static setRandomization(element: RandomizationElementType, lockedRefs: LockedItems, elements: RandomizationChildElementType[] = null, randomizationType: RandomizationMethod = null, newDisplayLimit?: number) {
    const existingRandomization = element[this.getRandomizationProperty(element)];

    randomizationType ??= existingRandomization?.getRandomizationMethod() ?? RandomizationMethod.Shuffle;
    elements ??= Randomization.getAllChildElements(element) ?? [];
    newDisplayLimit = Number(newDisplayLimit ?? existingRandomization?.getRandomizedDisplayLimit()?.number ?? Randomization.DEFAULT_DISABLED_LIMIT);

    const isDisplayLimitDisabled = newDisplayLimit === Randomization.DEFAULT_DISABLED_LIMIT;

    const items: RandomizationItems = {};
    elements.forEach((item, index) => {
      // setting item to null if pinned and limit is not disabled, otherwise, a unique group number
      // i.e. each item in its own group means all items are randomized individually
      // TODO: will need to set to appropriate group numbers when support randomized nested groups
      items[item.ref] = { configuration: [(isDisplayLimitDisabled && lockedRefs[item.ref]) ? null : index + 1] };
    });

    //TODO: hardcoding limit in first group, will need to update when support nested groups
    //if display limit is not disabled value, then just ensure it's less than the max number of items
    const itemsLength = Object.keys(items).length;
    const allChildElementsLength = this.getAllChildElements(element).length;
    if (newDisplayLimit !== Randomization.DEFAULT_DISABLED_LIMIT && newDisplayLimit >= itemsLength) {
      newDisplayLimit = itemsLength > 0 ? itemsLength - 1 : allChildElementsLength - 1;
    }

    const userDefinedLimit = {
      number: newDisplayLimit,
      method: DisplayLimitMethod.LeastResponseCount
    }

    const limits: DisplayLimitItem[] = [userDefinedLimit];

    //TODO: hardcoding type in first group, will need to update when support nested groups
    const methods = [randomizationType];
    element[this.getRandomizationProperty(element)] = new Randomization(items, methods, limits);
  }

  static getLockedItems(element: RandomizationElementType): LockedItems {
    const randomizationProperty = this.getRandomizationProperty(element);
    const randomizableItems = this.getAllChildElements(element);
    // TODO: get the first group in configuration, update when support nested groups
    const lockedItems = Object.entries(element[randomizationProperty].items)
      .reduce((acc, [ref, value]: [string, { configuration: number[] }]) => value?.configuration[0] === null ? {...acc, [ref]: true} : acc, {});
    return randomizableItems.reduce((acc, item) => {
      if (element[randomizationProperty].items[item.ref]?.configuration[0] === null) {
        return {...acc, [item.ref]: true};
      }
      return acc;
    }, lockedItems);
  }

  static getRandomizationTypeName(element: RandomizationElementType, title: boolean = false, plural: boolean = false): string {
    let typeName;

    if (element instanceof Page) {
      typeName = 'node';
    } else if (element instanceof Block) {
      typeName = 'page';
    } else if (element instanceof Survey) {
      typeName = 'block';
    }

    return i18n.global.t(`element.typeName${title ? 'Title' : ''}${plural ? 'Plural' : ''}.${typeName}`);
  }
}
