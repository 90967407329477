// Utilities
import {defineStore} from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    isDrawerOpen: false,
    drawerContent: '',
    rtlDirection: false,
    disabled: false,
    errorRetrievingSurvey: false,
  }),
  actions: {
    setDirection(){
      // There may be other cases, only for test now
      this.rtlDirection = window.getComputedStyle(document.body).direction == 'rtl' || navigator.language == 'ar';
    },
    setDisabled(disabled: boolean) {
      this.disabled = disabled;
    },
    failedRetrievingSurvey() {
      this.errorRetrievingSurvey = true;
      this.setDisabled(true);
    },
    retrievedSurvey() {
      this.errorRetrievingSurvey = false;
      this.setDisabled(false);
    }
  }
})
