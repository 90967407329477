import { uuid, stripHTML } from "@/utils/string";
import ValidationException from "@/exceptions/ValidationException";
import Survey, {ValidatePipesResult} from "@/models/Survey";
import i18n from "@/plugins/i18n";
import Asset from "./Asset";
import { Ref, Referable } from "@/typings/global-types";
import SurveyError, {
  NODE_ORDINALITIES_ERROR_PROPERTIES,
  SurveyErrorProperty,
  SurveyErrorType
} from "@/models/errors/SurveyError";
import {cloneDeep} from "@/utils/lodash";
import {LayoutAnswerColumns} from "@/models/nodeTypes/QuestionType1d";
import Option from "./Option";

export default abstract class Node<TNodeOrdinalities extends NodeOrdinalities = NodeOrdinalities> implements Referable {
  static readonly DEFAULT_HIDE: boolean = false;
  static readonly DEFAULT_RULE = null; //TODO: rule type
  static readonly DEFAULT_REQUIRE: boolean = true;
  static readonly DEFAULT_ORDINALITIES: NodeOrdinalities = {};
  static readonly ORDINALITY_TYPES: NodeOrdinalitiesTypes = {}; // used to check accepted ordinality types for node type, see @validateOrdinalities()
  static readonly NUM_SETS: number = 0;
  static readonly NODE_ICON: string;
  static readonly NODE_TITLE: string;
  static readonly NODE_EDIT_COMPONENT: string = null;
  static readonly NODE_REQUIRE: boolean = true;
  static readonly MAX_TEXT_LENGTH = 1200; //TODO: increase after performance testing

  type: NodeTypeId;
  ref: Ref;
  code: number;
  name: string;
  text: string;
  rule: string | null; //TODO: determines whether to show option or not given rule (not part of V1)
  hide: boolean;
  require: boolean;
  ordinalities = cloneDeep(Node.DEFAULT_ORDINALITIES) as TNodeOrdinalities;
  i18n: NodeTranslations = {};
  assets: Asset[];
  temporaryAssets: Asset[];

  protected constructor(
    baseNodeParams: BaseNodeParams,
    ordinalities = cloneDeep(Node.DEFAULT_ORDINALITIES) as TNodeOrdinalities,
  i18n: NodeTranslations = {}
  ) {
    const {
      type,
      survey,
      code,
      ref,
      name,
      text,
      rule,
      hide,
      require,
      assets,
      temporaryAssets
    } = baseNodeParams;

    if (!Object.values(NodeTypeId).includes(type)) {
      //TODO: include more helpful data from params, i.e. ref, name, etc.
      throw new ValidationException(
        `Invalid node type provided. Expected one of: ${Object.values(NodeTypeId).join(', ')}`
       );
    }

    this.type = type;
    this.name = name ?? '';
    this.ref = ref ?? uuid();
    this.code = code ?? null;
    this.text = text ?? '';
    this.rule = rule ?? Node.DEFAULT_RULE;
    this.hide = hide ?? Node.DEFAULT_HIDE;
    this.require = require ?? Node.DEFAULT_REQUIRE;
    this.ordinalities = ordinalities ?? this.getDefaultOrdinalities();
    this.i18n = Object.assign({}, i18n); // Coerce to an object
    this.assets = assets ?? [] as Array<Asset>;
    this.temporaryAssets = [...this.assets];

    this.validate(baseNodeParams.survey, null, false, false, true, false, false);
  }

  validate(
    survey: Survey,
    codeChangeMap: Map<number, number> = null,
    validateText: boolean = true,
    validateIncomplete: boolean = true,
    validateOrdinalities: boolean = true,
    validateMasking: boolean = true,
    validateMaskingOptions: boolean = false,
  ) {
    if (validateText) {
      this.validateText(survey, codeChangeMap, validateIncomplete);
    }

    if (validateOrdinalities) {
      this.validateOrdinalities(survey);
    }

    if (validateMasking) {
      this.validateMasking(survey, validateMaskingOptions);
    }
  }

  validateText(survey: Survey, codeChangeMap: Map<number, number> = null, validateIncomplete: boolean = false) {
    const hasText = this.text !== null && this.text !== '';
    if (hasText) {
      this.validatePipes(survey, codeChangeMap);
    }

    if (hasText && this.temporaryAssets.length) {
      this.validateAssets()
    }

    if (hasText || validateIncomplete) {
      this.validateTextContent(survey);
    }
  }

  validateTextContent(survey: Survey) {
    survey.resetSurveyErrors(this.ref, this.ref, [SurveyErrorProperty.NodeText]);

    // exceeds max length
    const stripped = stripHTML(this.text, true);
    if (stripped.length > Node.MAX_TEXT_LENGTH) {
      survey.addSurveyError(new SurveyError(SurveyErrorType.Length, SurveyErrorProperty.NodeText, this.ref, this.ref, {max: Node.MAX_TEXT_LENGTH}));
    }

    // no content
    //TODO: no longer validating on empty text, this will be moved to a warning validation system introduced in future, see BUILDER-1087.
    // if (!this.hasMedia() && !this.hasQuestionText()) {
    //   survey.addSurveyError(new SurveyError(SurveyErrorType.Empty, SurveyErrorProperty.NodeText, this.ref, this.ref));
    // }
  }

  validateAssets() {
    this.assets = this.temporaryAssets.filter((asset, index, tempAssets) => {
      const notRepeated = tempAssets.findIndex(tempAsset => (tempAsset.url === asset.url)) === index
      return notRepeated && this.text.includes(asset.url)
    })
  }

  validatePipes(survey: Survey, codeChangeMap: Map<number, number> = null): ValidatePipesResult {
    return survey.validatePipes(this, null, codeChangeMap);
  }

  validateMasking(survey: Survey, validateOptions: boolean = false) {
    // just ensure any errors are cleared, this method will be overridden in applicable node types
    survey.resetSurveyErrors(null, this.ref, [SurveyErrorProperty.OptionMaskingSet1, SurveyErrorProperty.OptionMaskingSet2]);
  }

  validateOrdinalities(survey: Survey) {
    this.resetOrdinalityErrors(survey);

    // set default ordinalities, if none set
    if (!this.ordinalities || !Object.keys(this.ordinalities).length) {
      this.setDefaultOrdinalities();
      return;
    }

    // validate, if not the defaults
    if (this.ordinalities !== this.getDefaultOrdinalities()) {
      const ordinalities = this.validateNestedOrdinalities(this.ordinalities, this.getDefaultOrdinalities());
      this.ordinalities = this.validateMissingNestedOrdinalities(ordinalities, this.getDefaultOrdinalities()) as TNodeOrdinalities;
    }
  }

  validateNestedOrdinalities(
    ordinalities: Partial<TNodeOrdinalities>,
    defaultOrdinalities: Partial<TNodeOrdinalities>,
    expectedTypesPath: string = ''
  ): Partial<TNodeOrdinalities> {

    for (const key in ordinalities) {
      const currentTypesPath = expectedTypesPath ? `${expectedTypesPath}.${key}` : key;
      if (!(key in defaultOrdinalities)) {
        // remove any oridinalities not existing for question type
        delete ordinalities[key];
      } else {
        // validate type
        const value = ordinalities[key];
        const actualType = (value === null ? 'null' : Array.isArray(value) ? 'array' : typeof value) as any;
        if (actualType === 'object') {
          if (typeof defaultOrdinalities[key] !== 'object') {
            // delete if not expecting an object, default will be added in @validateMissingNestedOrdinalities()
            delete ordinalities[key];
            continue;
          }
          // recursively handle nested objects
          this.validateNestedOrdinalities(ordinalities[key] as Partial<TNodeOrdinalities>, defaultOrdinalities[key] as Partial<TNodeOrdinalities>, currentTypesPath);
        } else {
          const expectedTypesKeys = currentTypesPath.split('.');
          const expectedTypes = expectedTypesKeys.reduce((acc, cur) => {
            return (acc && acc[cur] as NodeOrdinalitiesTypes);
          }, (this.constructor as typeof Node).ORDINALITY_TYPES);
          const expectedTypesArray = (Array.isArray(expectedTypes)
            ? expectedTypes
            : [expectedTypes]) as NodeOrdinalitiesTypes[];

          let isValid = false;

          // check arrays and enums first
          for (const expectedType of expectedTypesArray) {
            if (typeof expectedType === 'string' && (expectedType as string).endsWith('[]')) {
              // check expected array types (i.e. 'string[]')
              const baseType = (expectedType as string).slice(0, -2); // i.e. string
              if (Array.isArray(value)) {
                isValid = value.every(item => typeof item === baseType); // at least for now, they should all be the same type
                if (isValid) {
                  break;
                }
              }
            } else if (typeof expectedType === 'object') {
              // check enums
              if (Object.values(expectedType as object).includes(value)) {
                isValid = true;
                break;
              }
            }
          }

          // check standard types
          if (!isValid && !expectedTypesArray.includes(actualType)) {
            // delete if type doesn't match, default will be added in @validateMissingNestedOrdinalities()
            delete ordinalities[key];
          }
        }
      }
    }

    return ordinalities;
  }

  validateMissingNestedOrdinalities(
    ordinalities: Partial<TNodeOrdinalities>,
    defaultOrdinalities: Partial<TNodeOrdinalities>
  ): Partial<TNodeOrdinalities> {
    // add any missing ordinalities and set to defaults
    for (const key in defaultOrdinalities) {
      if (!(key in ordinalities)) {
        ordinalities[key] = defaultOrdinalities[key];
      } else if (typeof defaultOrdinalities[key] === 'object' && defaultOrdinalities[key] !== null) {
        // check nested objects
        this.validateMissingNestedOrdinalities(ordinalities[key] as TNodeOrdinalities, defaultOrdinalities[key] as TNodeOrdinalities);
      }
    }

    return ordinalities;
  }

  getDefaultOrdinalities(): TNodeOrdinalities {
    return cloneDeep((this.constructor as typeof Node).DEFAULT_ORDINALITIES) as TNodeOrdinalities;
  }

  setDefaultOrdinalities() {
    this.ordinalities = this.getDefaultOrdinalities();
  }

  resetOrdinalityErrors(survey: Survey) {
    survey.resetSurveyErrors(this.ref, null, NODE_ORDINALITIES_ERROR_PROPERTIES);
  }

  hasQuestionText(): boolean {
    return (this.text !== null && this.text.trim() !== '');
  }

  hasMedia(): boolean {
    return (this.assets !== null && this.assets.length > 0);
  }

  getCodeLabel(isPipe = false): string {
    return this.code !== null ? `${isPipe ? '{{' : ''}${i18n.global.t('question.questionInitial') + this.code}${isPipe ? '}}' : ''}` : '';
  }

  getCleanName(includeNoTextStr = true, returnCodeIfNoName = false): string {
    if (this.name?.length) {
      return this.name;
    }

    return returnCodeIfNoName
      ? this.getCodeLabel(false)
      : this.text?.length
        ? this.getCleanText()
        : includeNoTextStr
          ? i18n.global.t('question.noQuestionLabel')
          : '';
  }

  getCleanText(): string {
    return this.text?.length
      ? stripHTML(this.text).replace(/(\s+|\t+)/g, ' ')
      : '';
  }

  getCleanTitle(includeNoTextStr = true, maxLength: number = null): string {
    let name = this.getCleanName(includeNoTextStr);

    if (name !== null && maxLength !== null && name.length > maxLength) {
      name = name.slice(0, maxLength - 3) + '...';
    }

    return this.getCodeLabel() + (name !== null && name.trim().length ? ': ' + name : '');
  }

  addTranslation(locale: string, translation: string|null = null) {
    this.i18n[locale] = translation;
  }

  getRefs(): Array<Ref> {
    const refs: Array<Ref> = []

    refs.push(...this.assets.map((asset: Asset) => asset.ref))

    return refs
  }

  addAsset(asset: Asset) {
    this.assets.push(asset)
    this.temporaryAssets.push(asset)
  }

  getOptionsBySetRef(survey: Survey, refSet1: string): Option[] {
    const set1 = survey.getOptionSetByRef(refSet1)
    const options = set1?.options ? [...set1.options] : []

    return options
  }

  removeNodeOptionsAssetNotSupported(survey: Survey, refSet1: string) {
    const options = this.getOptionsBySetRef(survey, refSet1)
    if (!this.constructor['NODE_SUPPORT_SET1_OPTIONS_ASSETS']) {
      options.forEach(option => {
        option.asset = null
      })
    }
  }
}

export interface BaseNodeParams {
  type: NodeTypeId,
  survey: Survey,
  ref?: string,
  code?: number,
  name?: string,
  text?: string,
  rule?: string,
  hide?: boolean,
  require?: boolean,
  ordinalities?: object,
  assets?: Array<Asset>,
  temporaryAssets?: Array<Asset>,
}

export enum NodeTypeId {
  Info = 'info',
  SingleSelect = 'single-select',
  SingleSelectDropDown = 'single-select-dropdown',
  MultiSelect = 'multi-select',
  Text = 'text',
  Numeric = 'numeric',
  NumericSlider = 'numeric-slider',
  SingleSelectGrid = 'single-select-grid',
  SingleSelectGridScale = 'single-select-grid-scale',
  MultiSelectGrid = 'multi-select-grid',
  AllocationSlider = 'allocation-slider',
  RankOrder = 'rank-order'
}

// node types that will be hidden from selection
export const HIDDEN_NODE_TYPES: NodeTypeId[] = [
  NodeTypeId.AllocationSlider,
]

export const NODE_INFO_TYPES: NodeTypeId[] = [
  NodeTypeId.Info,
];

export const QUESTION_0D_TYPES: NodeTypeId[] = [
  NodeTypeId.Text,
  NodeTypeId.Numeric,
  NodeTypeId.NumericSlider,
];

export const QUESTION_1D_SELECT_TYPES: NodeTypeId[] = [
  NodeTypeId.SingleSelect,
  NodeTypeId.SingleSelectDropDown,
  NodeTypeId.MultiSelect,
];


export const QUESTION_SINGLE_SELECTION_TYPES: NodeTypeId[] = [
  NodeTypeId.SingleSelect,
  NodeTypeId.SingleSelectDropDown,
  NodeTypeId.SingleSelectGrid,
  NodeTypeId.SingleSelectGridScale,
  NodeTypeId.RankOrder,
];

export const QUESTION_MULTI_SELECTION_TYPES: NodeTypeId[] = [
  NodeTypeId.MultiSelect,
  NodeTypeId.MultiSelectGrid,
];

export const QUESTION_1D_TYPES: NodeTypeId[] = [
  ...QUESTION_1D_SELECT_TYPES,
  NodeTypeId.AllocationSlider
];

export const QUESTION_2D_TYPES: NodeTypeId[] = [
  NodeTypeId.SingleSelectGrid,
  NodeTypeId.SingleSelectGridScale,
  NodeTypeId.MultiSelectGrid,
  NodeTypeId.RankOrder,
];

export const QUESTION_2D_TYPES_EDITABLE_COLUMNS: NodeTypeId[] = [
  NodeTypeId.SingleSelectGrid,
  NodeTypeId.MultiSelectGrid,
];

export const QUESTION_NUMERIC_TYPES: NodeTypeId[] = [
  NodeTypeId.Numeric,
  NodeTypeId.NumericSlider,
  NodeTypeId.AllocationSlider,
]

export const QUESTION_TYPES: NodeTypeId[] = [
  ...QUESTION_0D_TYPES,
  ...QUESTION_1D_TYPES,
  ...QUESTION_2D_TYPES,
];

export const QUESTION_CHOICE_TYPES: NodeTypeId[] = [...QUESTION_1D_TYPES, ...QUESTION_2D_TYPES];

//TODO: for V1, we are only supporting 1d select and 0d types; for V2 replace below with QUESTION_TYPES to support all types
export const QUESTION_TYPES_SUPPORTED_ROUTING: NodeTypeId[] = [...QUESTION_1D_TYPES, ...QUESTION_0D_TYPES, ...QUESTION_2D_TYPES];

export const QUESTION_TYPES_SUPPORTED_PIPING: NodeTypeId[] = [
  NodeTypeId.Text,
  NodeTypeId.Numeric,
  NodeTypeId.SingleSelect,
  NodeTypeId.SingleSelectDropDown,
];

export const QUESTION_TYPES_SUPPORTED_MASKING: NodeTypeId[] = [...QUESTION_CHOICE_TYPES];

export const QUESTION_TYPES_SUPPORTED_QUESTION_QUOTA_GROUPS: NodeTypeId[] = [...QUESTION_1D_SELECT_TYPES];
export const QUESTION_TYPES_SUPPORTED_OPTIONS_ASSETS: NodeTypeId[] = [
  NodeTypeId.SingleSelect,
  NodeTypeId.MultiSelect,
  NodeTypeId.RankOrder
]

export interface NodeOrdinalities {}

// types expected for ordinality properties, defined in ORDINALITY_TYPES on each node class,
// add '[]' for arrays if more supported in future, i.e., 'number[]'
export type NodeOrdinalityTypes =
  | 'string'
  | 'number'
  | 'boolean'
  | 'null'
  | 'string[]'
  | LayoutAnswerColumns
  | NodeOrdinalityTypes[]
  | { [key: string]: NodeOrdinalityTypes };

export interface NodeOrdinalitiesTypes {
  [key: string]: NodeOrdinalityTypes;
}

export type NodeTranslations = Record<string, string>;
