import {BaseNodeParams, NodeTranslations} from "@/models/Node";
import QuestionType1d, {
  QuestionType1dOrdinalities
} from "@/models/nodeTypes/QuestionType1d";
import {OptionSetParams} from "@/models/nodeTypes/QuestionType1d";
import Survey from "@/models/Survey";
import OptionSet, {OptionSetOrder} from "@/models/OptionSet";
import Option from "@/models/Option";
import { Ref } from "@/typings/global-types";
import {cloneDeep} from "@/utils/lodash";

/**
 * Question types with two option sets, such as grids.
 */
export default abstract class QuestionType2d<
  TQuestionType2dOrdinalities extends QuestionType2dOrdinalities = QuestionType2dOrdinalities
> extends QuestionType1d<TQuestionType2dOrdinalities> {

  static readonly NUM_SETS: number = 2;
  static readonly DEFAULT_ORDINALITIES: QuestionType2dOrdinalities = {};
  static readonly NODE_EDIT_COMPONENT: string = 'QuestionType2dForm';
  static readonly NODE_SET2_SUPPORTS_SPECIFY: boolean = false;
  static readonly NODE_SET2_SUPPORTS_EXCLUSIVE: boolean = false;
  static readonly NODE_SET2_HIDDEN: boolean = false; // whether set2/columns are shown for editing in UI
  static readonly NODE_SET2_NUMERIC: boolean = false;

  set2: Ref;

  protected constructor(
      baseNodeParams: BaseNodeParams,
      ordinalities = cloneDeep(QuestionType2d.DEFAULT_ORDINALITIES) as TQuestionType2dOrdinalities,
      i18n: NodeTranslations = {},
      optionSetParams: OptionSetParams = {},
  ) {
    super(baseNodeParams, ordinalities, i18n, optionSetParams);

    const {survey} = baseNodeParams;
    const {addSet2PlaceholderOption, set2, optionConfigurations, optionMasking } = optionSetParams;

    this.set2 = set2 ?? null;

    this.validateOptionSet('set2', optionConfigurations ?? {}, survey, optionMasking?.set2 ?? [], false, false);

    // add placeholder
    if (addSet2PlaceholderOption ?? true) {
      this.getSet2(survey).addPlaceholderOption(this);
    }
  }

  getSet2(survey: Survey): OptionSet | null {
    return survey.getOptionSetByRef(this.set2);
  }

  getSet2Options(survey: Survey, includePlaceholder: boolean = true): Option[] | null {
    const set = this.getSet2(survey);
    const options = set?.options ?? null;
    return includePlaceholder ? options : options?.filter(option => option !== set.getPlaceholderOption()) ?? null;
  }

  getOptionByRef(optionRef: string, survey: Survey): Option | null {
    return this.getSet1(survey).getOptionByRef(optionRef) ?? this.getSet2(survey).getOptionByRef(optionRef);
  }

  getMinNumColumnsAllowed(survey: Survey = null): number {
    return 1;
  }

  getMaxNumColumnsAllowed(survey: Survey = null): number {
    return null;
  }

  getDefaultNumColumns(): number {
    return 1;
  }

  updateNumericColumns(survey: Survey, newNumColumns: number = null) {
    const currentNumColumns = this.getSet2Options(survey).length;

    if (newNumColumns < currentNumColumns) {
      this.removeNumericColumn(currentNumColumns - newNumColumns, survey);
    } else if (newNumColumns > currentNumColumns) {
      this.addNumericColumn(newNumColumns - currentNumColumns, survey)
    }
  }

  validateNumericColumns(survey: Survey) {
    let columns = this.getSet2(survey);

    // check if it's a shared option set, if so, then create a new one - we're not sharing
    // or masking on columns and want to ensure the properties are set appropriately when changing types
    // TODO: this will need more thought when get to validating logic upon changing question types
    //  (i.e. likely need to warn/clear any logic on columns / not carry forward logic on set2)
    const shared = survey.isOptionSetInUse(columns, this);
    if (shared) {
      const set = new OptionSet();
      survey.addOptionSet(set);

      this.set2 = set.ref;
      columns = set;

      this.addNumericColumn(set.getOptionsLength(), survey);
    }

    // ensure there's no placeholder (i.e. if changing between question types)
    columns.deletePlaceholderOption(survey, this);
    const numColumns = columns.getOptionsLength();

    // ensure there are not more or less columns than allowed (i.e. if changing between question types)
    const min = this.getMinNumColumnsAllowed(survey);
    const max = this.getMaxNumColumnsAllowed(survey)
    if (!numColumns) {
      this.addNumericColumn(this.getDefaultNumColumns(), survey);
    } else if (numColumns > max) {
      this.removeNumericColumn(numColumns - max, survey)
    } else if (numColumns < min) {
      this.addNumericColumn(min - numColumns, survey)
    }

    // ensure labels are numeric and in order, pin, exclusive and specify should all be false
    columns.options.forEach((option, index) => {
      option.label = String(index + 1);
      option.code = index + 1;
      option.pin = false;
      option.exclusive = false;
      option.specify = false;
    })

    // ensure order is not shuffled
    columns.order = OptionSetOrder.Normal;

    // ensure column options are not set to hidden
    for (const key in this.optionConfigurations) {
      if (columns.options.find(option => option.ref === key)) {
        this.optionConfigurations[key].hide = false;
      }
    }
  }

  removeNumericColumn(numToRemove: number, survey: Survey) {
    const columns = this.getSet2(survey);
    const currNumColumns = columns.options.length;
    const minAllowed = this.getMinNumColumnsAllowed(survey);

    for (let i = currNumColumns - 1; i >= currNumColumns - numToRemove; i--) {
      // ensure we don't remove more than is allowed
      if (columns.options.length <= minAllowed) {
        break;
      }
      columns.deleteOptionByIndex(survey, i, this, 'set2');
    }
  }

  addNumericColumn(numToAdd: number, survey: Survey) {
    const columns = this.getSet2(survey);
    const nextLabel = columns.options.length + 1;
    const maxAllowed = this.getMaxNumColumnsAllowed(survey);

    for (let i = 0; i < numToAdd; i++) {
      // ensure we don't add more than is allowed
      if (columns.options.length >= maxAllowed) {
        break;
      }
      const newColumn = new Option(nextLabel + i, null, String(nextLabel + i));
      columns.addOption(newColumn, null, this);
    }
  }

  getRefs(): Array<Ref> {
    const refs = super.getRefs()

    refs.push(this.set2)

    return refs
  }
}

export interface QuestionType2dOrdinalities extends QuestionType1dOrdinalities {}
